import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { IAplicacao } from './types';
import InputText from '~/components/Inputs/InputText';
import ToggleDefault from '~/components/ToggleDefault';
import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const CadastroGrupoAplicacao: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [formData, setFormData] = useState<IAplicacao>({
    cod_grupo: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    des_nome: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    des_base_url: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    flg_inativo: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    reset();
    setFormData({
      ...formData,
      des_nome: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
      des_base_url: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
      flg_inativo: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
    });

    setInitInicializado(false);
  }, [formData, reset]);

  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
  }, [formData]);

  const handleChangeDescricao = useCallback(
    (val: string) => {
      setFormData({
        ...formData,
        des_nome: {
          ...formData.des_nome,
          value: val.trimStart(),
          isInvalid: val.trim() === '',
        },
      });
      setValue('des_nome', val.trimStart());
    },
    [formData, setValue],
  );

  const handleChangeBaseUrl = useCallback(
    (val: string) => {
      setFormData({
        ...formData,
        des_base_url: {
          ...formData.des_base_url,
          value: val.trimStart(),
          isInvalid: val.trim() === '',
        },
      });

      setValue('des_base_url', val.trimStart());
    },
    [formData, setValue],
  );

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_grupo, des_nome, des_base_url, flg_inativo } = row;

    setValue('des_nome', des_nome);
    setValue('des_base_url', des_base_url);
    setValue('flg_inativo', flg_inativo);

    setFormData({
      ...formData,
      cod_grupo: {
        value: cod_grupo,
        isInvalid: false,
        isRequired: false,
      },
      des_nome: {
        value: des_nome,
        isInvalid: false,
        isRequired: true,
      },
      des_base_url: {
        value: des_base_url,
        isInvalid: false,
        isRequired: true,
      },
      flg_inativo: {
        value: flg_inativo,
        isInvalid: false,
        isRequired: false,
      },
    });
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/grupo-de-aplicacao/${formData?.cod_grupo.value}`,
          {
            des_nome: dataForm.des_nome.trim(),
            des_base_url: dataForm.des_base_url,
            flg_inativo: dataForm.flg_inativo,
          },
        );
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setUpdate(false);
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/grupo-de-aplicacao', {
        des_nome: dataForm.des_nome.trim(),
        des_base_url: dataForm.des_base_url,
        flg_inativo: dataForm.flg_inativo,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const res = await api.delete(
      `/grupo-de-aplicacao/${formData?.cod_grupo.value}`,
    );
    const { success, message } = res.data;
    resetFormData();
    setUpdate(false);
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={194}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={194}
          title="Cadastro de Grupo de Aplicação"
          codigoRegistro={[
            {
              value: formData.cod_grupo.value,
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-5">
              <InputText
                label="Descrição"
                value={formData.des_nome.value}
                maxLength={100}
                toUpperCase
                placeholder="Informe a Descrição"
                isEmpty
                isNull
                isUndefined
                isRequired
                setInvalid={!!errors.des_nome}
                iniInicializado={!!errors.des_nome}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeDescricao(newValue);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-5">
              <InputText
                label="Base URL"
                value={formData.des_base_url.value}
                maxLength={100}
                placeholder="Informe a Base URL"
                isEmpty
                isNull
                isUndefined
                isRequired
                setInvalid={!!errors.des_base_url}
                iniInicializado={!!errors.des_base_url}
                onChange={(newValue: string) => {
                  handleChangeBaseUrl(newValue);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-1">
              <ToggleDefault
                labelText="Inativo?"
                setChecked={formData.flg_inativo.value}
                onSwitch={() => {
                  setValue('flg_inativo', !formData.flg_inativo.value);
                  setFormData({
                    ...formData,
                    flg_inativo: {
                      ...formData.flg_inativo,
                      value: !formData.flg_inativo.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroGrupoAplicacao;
